@import '~@angular/material/theming';
@import '~src/values/colors';
@import '~src/values/dimens';

/* Customize Angular Material typography */
@font-face {
  font-family : Oswald;
  src         : url(assets/fonts/Oswald.ttf) format("opentype");
}
@font-face {
  font-family : Rubik;
  src         : url(assets/fonts/Rubik-VariableFont_wght.ttf) format("opentype");
}
$sp-custom-typography: mat-typography-config(
  $font-family: Rubik
);
@include mat-core($sp-custom-typography);

/* Customize Angular Material theme */
$irent-primary  : mat-palette($irt-primary-palette);
$irent-accent   : mat-palette($irt-accent-palette);
$irent-warn     : mat-palette($mat-red);
$irent-theme    : mat-light-theme(
    $irent-primary,
    $irent-accent,
    $irent-warn
);
@include angular-material-theme($irent-theme);

/* Customize project global styles */
.mat-dialog-container {
  padding       : 0 !important;
  border-radius : $irt-border-radius !important;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Rubik, Oswald, Roboto, "Helvetica Neue", sans-serif;
}

.mat-table {
  background-color:transparent!important;
}

table {
  width           : 100%;
  border-collapse : collapse;
  border-radius   : $irt-border-radius;
}

table tr:last-child td {
  border-bottom: 0 solid;
}

td.mat-cell {
  padding: calc(#{$irt-half-margin} / 4) calc(#{$irt-half-margin} / 2) !important;
}

th.mat-header-cell {
  padding: calc(#{$irt-half-margin} / 4) calc(#{$irt-half-margin} / 2) !important;
}

tr.mat-row:hover {
  cursor: pointer;
  @include mat-elevation($irt-border-elevation,mat-contrast($irt-primary-palette,800));
}

.irt-no-padding-field .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-header-row {
  background-color  : mat-contrast($irt-primary-palette, 600);
  border-radius     : $irt-border-radius;
}

.mat-icon-button {
  border-radius     : calc(#{$irt-border-radius} / 2) !important;
  background-color  : mat-contrast($irt-primary-palette,600);
}

.irt-primary-color {
  color: mat-contrast($irt-primary-palette,500);
}

.irt-primary-medium-color {
  color: mat-contrast($irt-primary-palette,900);
}

.irt-accent-color {
  color: mat-color($irt-accent-palette, 500);
}

.irt-accent-background {
  background-color: mat-color($irt-accent-palette, 500);
}

.irt-primary-medium-background {
  background-color: mat-contrast($irt-primary-palette,600);
}

.irt-no-margin {
  margin: 0 !important;
}

.irt-margin {
  margin: $irt-margin !important;
}

.irt-half-margin {
  margin: $irt-half-margin !important;
}

.irt-margin-left {
  margin-left: $irt-margin !important;
}

.irt-margin-right {
  margin-right: $irt-margin !important;
}

.irt-margin-top {
  margin-top: $irt-margin !important;
}

.irt-margin-bottom {
  margin-bottom: $irt-margin !important;
}

.irt-double-margin-top {
  margin-top: $irt-double-margin !important;
}

.irt-double-margin-bottom {
  margin-bottom: $irt-double-margin !important;
}

.irt-double-margin-left {
  margin-left: $irt-double-margin !important;
}

.irt-half-margin-bottom {
  margin-bottom: $irt-half-margin !important;
}

.irt-half-margin-top {
  margin-top: $irt-half-margin !important;
}

.irt-half-margin-left {
  margin-left: $irt-half-margin !important;
}

.irt-half-margin-right {
  margin-right: $irt-half-margin !important;
}

.irt-padding {
  padding     : $irt-margin;
  box-sizing  : border-box;
}

.irt-full-width {
  width: 100%;
}

.irt-half-width {
  width: 48.5%;
}

.irt-quarter-width {
  width: 24.25%;
}

.irt-third-width {
  width: 32%;
}

.irt-three-third-width {
  width: 63%;
}

.irt-full-height {
  height: 100%;
}

.irt-centered-text {
  text-align: center;
}

.irt-right-text {
  text-align: right;
}

.irt-spaced-row {
  display         : flex !important;
  align-items     : center;
  justify-content : space-between;
}

.irt-centered-row {
  display         : flex !important;
  align-items     : center;
  justify-content : center;
}

.irt-end-row {
  display         : flex !important;
  align-items     : center;
  justify-content : flex-end;
}

.irt-wrapped-row {
  display   : flex !important;
  flex-wrap : wrap;
}

.irt-row {
  display   : flex;
}

.irt-centered-column {
  display         : flex !important;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
}

.irt-main-button {
  border-radius       : $irt-button-border-radius !important;
  border              : $irt-border-width solid mat-color($irt-primary-palette, 500) !important;
  background-color    : mat-color($irt-primary-palette, 500);
  color               : mat-contrast($irt-primary-palette, 500);
  box-sizing          : border-box;
}

.irt-main-disabled-button {
  border-radius       : $irt-button-border-radius !important;
  border              : $irt-border-width solid mat-color($irt-primary-palette, 500) !important;
  background-color    : mat-color($irt-primary-palette, 500);
  color               : mat-contrast($irt-primary-palette, 500) !important;
  opacity             : 0.5;
  box-sizing          : border-box;
}

.irt-secondary-button {
  border-radius       : $irt-button-border-radius !important;
  border              : $irt-border-width solid mat-color($irt-primary-palette, 500) !important;
  color               : mat-color($irt-primary-palette, 500);
  box-sizing          : border-box;
}

.irt-title {
  font-size: $irt-title-font-size;

  @media (max-width: ($irt-md - 1)) {
    font-size: $irt-title-small-font-size;
  }

  @media (min-width: $irt-md) and (max-width: $irt-lg) {
    font-size: $irt-title-medium-font-size;
  }
}

.irt-ellipsized-text {
  width         : 100%;
  white-space   : nowrap;
  overflow      : hidden;
  text-overflow : ellipsis;
}

.irt-cursor-pointer {
  cursor: pointer;
}

.irt-hover-text:hover {
  text-decoration: underline;
}

.irt-hero-fab-button {
  position  : absolute !important;
  bottom    : $irt-margin;
  right     : $irt-margin;
}

.irt-toolbar {
  @media (min-width: $irt-lg) {
    display: none;
  }
}

.irt-navbar {
  width: $irt-navbar-width;
  height: 100%;

  @media (max-width: ($irt-lg - 1)) {
    display: none;
  }
}

.irt-section {
  width   : calc(100% - #{$irt-navbar-width});
  height  : 100%;

  @media (max-width: ($irt-lg - 1)) {
    width       : 100%;
    margin-top  : $irt-toolbar-height;
  }
}

.irt-reservation-pending-status {
  background-color: mat-color($irt-reservation-status-palette,50);
}

.irt-reservation-confirmed-status {
  background-color: mat-color($irt-reservation-status-palette,100);
}

.irt-reservation-running-status {
  background-color  : mat-color($irt-reservation-status-palette,200);
  color             : white;
}

.irt-reservation-closed-status {
  background-color  : mat-color($irt-reservation-status-palette,300);
  color             : white;
}

.irt-reservation-expired-status {
  background-color  : mat-color($irt-reservation-status-palette,400);
  color             : white;
}

.irt-vehicle-free-status {
  background-color  : mat-color($irt-vehicle-status-palette,50);
}

.irt-vehicle-scheduled-status {
  background-color  : mat-color($irt-vehicle-status-palette,100);
  color             : white;
}

.irt-vehicle-busy-status {
  background-color  : mat-color($irt-vehicle-status-palette,200);
  color             : white;
}

.irt-vehicle-hidden-status {
  background-color  : mat-color($irt-vehicle-status-palette,300);
  color             : black;
}

.irt-form {
  display         : flex;
  justify-content : space-between;
  flex-wrap       : wrap;
}

.irt-spinner-layout {
  padding         : $irt-half-margin 0;
  box-sizing      : border-box;
  display         : flex;
  align-items     : center;
  justify-content : center;
}

.mat-form-field-wrapper {
  margin: 0 !important;
}

.irt-border-radius {
  border-radius: $irt-border-radius;
}

.irt-border-top-left-radius {
  border-top-left-radius: $irt-border-radius;
}

.irt-border-top-right-radius {
  border-top-right-radius: $irt-border-radius;
}

.irt-hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.irt-hide-scrollbar::-webkit-scrollbar {
  width: 0 !important
}

.irt-small-text {
  font-size: 12px;
}

.irt-table-container {
  border-radius: $irt-border-radius;
  @include mat-elevation($irt-border-elevation,mat-contrast($irt-primary-palette,800));
}